<template>
	<div class="editor_container" v-if="isShow">
		<!-- 弹窗 -->
		<div class="zezhao888" @click="clickclose"></div>
		<div class="tanchuandiv">
			<div class="content">
				<div class="titlename">把它收藏到</div>
				<div class="listbox">
					<div class="item" v-for="(item,index) in datalist" :key="index" @click="handleindex(index)">
						<img class="icon1" :src="require('@/assets/static/bookicon.png')"></img>
						<div class="text clamp">{{item.title}}</div>
						<div class="starbox">
							<img v-if="item.ifcollect" class="img100" :src="require('@/assets/static/star_icon_checked.png')"></img>
							<img v-else class="img100" :src="require('@/assets/static/star_icon.png')"></img>
						</div>
					</div>
					<!-- <div class="item">
						<img class="icon1" :src="require('@/assets/static/bookicon.png')"></img>
						<div class="text clamp">默认单词本</div>
						<div class="starbox">
							<img class="img100" src="/static/images/star_yellow.png"></img>
						</div>
					</div> -->
				</div>
				<div class="btn-group">
					<div class="btn-item btn1" @click="clickclose">取消</div>
					<div class="btn-item btn2" @click="handlecollect">确定</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		list: {
			type: Array,
			default: [],
		},
		isShow: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			title:"收藏组件",
			datalist:[],
		};
	},
	watch: {
	    list: {
			immediate:true,
	        handler(newVal, objVal) {
	            this.datalist = newVal
	        },
	    },
	},
	mounted() {},
	methods: {
		addClick(){
			var isclickadd = this.isclickadd
			this.isclickadd = !isclickadd
		},
		//关闭
		clickclose(){
			this.$emit("clickclose",false)
		},
		//选择
		handleindex(index){
			var datalist = this.datalist
			datalist[index].ifcollect = !datalist[index].ifcollect
			this.datalist = datalist
		},
		//确认
		handlecollect(){
			this.$emit("handlecollect",this.datalist)
		},
		
	}
};
</script>

<style lang="scss" scoped>
.editor_container{
    position: relative;
    z-index: 99;
	width: 100%;
	height: 100%;
}
.zezhao888 {
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.7);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 888;
}
.tanchuandiv {
	width: 586px;
	height: 504px;
	display: flex;
	align-items: center;
	position: fixed;
	left: 50%;
	margin-left: -293px;
	top: 20%;
	z-index: 999;
	.content{
		width: 100%;
		padding: 16px;
		box-sizing: border-box;
		background-color: #fff;
		border-radius: 8px;
		.titlename{
			color: #5C3704;
			text-align: center;
			font-size: 28px;
		}
		.listbox{
			margin-top: 38px;
			width: 100%;
			max-height:386px;
			overflow: auto;
			.item{
				width: 100%;
				display: flex;
				align-items: center;
				border-radius: 6px;
				background: #FFF9E9;
				padding: 9px;
				box-sizing: border-box;
				margin-bottom: 10px;
				cursor: pointer;
				.icon1{
					width: 28px;
					height: 28px;
					flex-shrink: 0;
				}
				.text{
					flex: 1;
					margin-left: 18px;
					color: #333;
					font-size: 16px;
				}
				.starbox{
					margin-left: auto;
					flex-shrink: 0;
					width: 48px;
					height: 48px;
				}
			}
		}
		.btn-group {
			margin-top: 28px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.btn1{
				border: 1px solid #499DFF;
				background: #67ADFF;
			}
			.btn2{
				border: 1px solid #68CDA6;
				background: #51D9A5;
			}
		}
		.btn-item {
			flex: 1;
			margin-left: 16px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			font-size: 16px;
			height: 48px;
			border-radius: 8px;
			cursor: pointer;
			&:first-child {
				margin-left: 0;
			}
		}
	}
}
</style>
